import React, { useState } from 'react';
import lodash from 'lodash';
import { Button, Grid, Icon, Modal, Segment } from 'semantic-ui-react';
import { prompt } from 'shared/globalModal';
import FieldManager from 'model-editor/molecules/FieldManager';

const ActionIcon = ({ icon, color, tooltip, onClick }) => {
    return <Icon
        size='large'
        name={icon}
        color={color}
        onClick={onClick}
        title={tooltip}
        link
    />;
};

const TemplateCard = ({ name, onEdit, onDelete }) => {
    return (
        <Segment>
            <Grid columns={2} verticalAlign='middle'>
                <Grid.Column>
                    {name}
                </Grid.Column>
                <Grid.Column textAlign='right'>
                    <ActionIcon color='black' icon='pencil' tooltip='Redigér' onClick={onEdit} />
                    <ActionIcon color='red' icon='x' tooltip='Slet' onClick={onDelete} />
                </Grid.Column>
            </Grid>
        </Segment>
    );
};

const TemplateEditorModal = ({ template, fieldIds, onClose, onChange, radioOptionsGetter, subResourceOptions }) => {
    if (!template) {
        return null;
    }

    const modalContentHeight = 'calc(95vh - 5em)';

    return (
        <Modal onClose={onClose} open={!!template} size='fullscreen' closeIcon>
            <Modal.Header>{template.name}</Modal.Header>
            <Modal.Content scrolling style={{ minHeight: modalContentHeight, padding: '0px' }}>
                <FieldManager
                    stickyMenu
                    disableNodeJump
                    fields={template.fields}
                    nodes={fieldIds}
                    fieldIds={fieldIds}
                    modelId={null}
                    printable={true}
                    onChange={onChange}
                    minHeight={modalContentHeight}
                    getOptionsByID={radioOptionsGetter}
                    resourceNodes={subResourceOptions}
                    stickyOffset='0px'
                />
            </Modal.Content>
        </Modal>
    );
};

const FieldTemplateEditor = ({ onChange, fieldTemplates, fieldIds, radioOptionsGetter, subResourceOptions }) => {
    const [currentTemplateIndex, setCurrentTemplateIndex] = useState(-1);

    const updateFieldTemplate = (index, propertyPath, value) => {
        lodash.set(fieldTemplates[index], propertyPath, value);
        onChange(fieldTemplates);
    };

    const removeFieldTemplate = index => {
        fieldTemplates.splice(index, 1);
        onChange(fieldTemplates);
    };

    const createNewFieldTemplate = async () => {
        const templateName = await prompt('Indtast navn på skabelon');
        if (!templateName || !templateName.trim()) {
            return;
        }

        onChange([...fieldTemplates, {
            name: templateName,
            fields: [],
        }]);
    };

    return (
        <div>
            <Grid columns={2}>
                {fieldTemplates.map((template, i) => {
                    return (
                        <Grid.Column>
                            <TemplateCard
                                onDelete={() => removeFieldTemplate(i)}
                                onEdit={() => setCurrentTemplateIndex(i)}
                                {...template}
                            />
                        </Grid.Column>
                    );
                })}
            </Grid>
            <Segment basic textAlign='right'>
                <Button
                    onClick={createNewFieldTemplate}
                    content='Opret feltskabelon'
                    icon='plus'
                    primary
                />
            </Segment>
            <TemplateEditorModal
                template={currentTemplateIndex !== -1 && fieldTemplates[currentTemplateIndex]}
                fieldIds={fieldIds}
                onChange={fields => updateFieldTemplate(currentTemplateIndex, 'fields', fields)}
                onClose={() => setCurrentTemplateIndex(-1)}
                radioOptionsGetter={radioOptionsGetter}
                subResourceOptions={subResourceOptions}
            />
        </div>
    );
};

export default FieldTemplateEditor;