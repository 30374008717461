import React, { useState } from 'react';
import { Checkbox, Dropdown, Header, Icon, List, Menu, Modal, Table } from 'semantic-ui-react';
import { dataTypeDescriptions, dataTypes } from 'model-editor/nodeMetadata';
import copyToClipboard from 'shared/copyToClipboard';
import QuestionEditor from './QuestionEditor';
import CalculationEditor from './CalculationEditor';
import FieldTemplateEditor from './FieldTemplateEditor';
import ColoredText from 'atoms/ColoredText';
import collectAllTagsAssociatedWithResource from './collectAllTagsAssociatedWithResource';

const ResourceDescription = ({ properties, annualRolloverValues = [], onSubResourceSelected }) => {
    return (
        <>
            <h3>Ressourceegenskaber</h3>
            <Table basic>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Navn</Table.HeaderCell>
                        <Table.HeaderCell>Tag</Table.HeaderCell>
                        <Table.HeaderCell>Datatype</Table.HeaderCell>
                        <Table.HeaderCell>Påkrævet?</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {properties.map(({ name, tag, dataType, required }) => {
                        return (
                            <Table.Row key={tag}>
                                <Table.Cell>
                                    {dataType === dataTypes.RESOURCE && (
                                        <ColoredText
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => onSubResourceSelected(tag)}
                                            icon='pencil'
                                            iconPosition='left'
                                            color='black'
                                            content={name}
                                        />
                                    )}
                                    {dataType !== dataTypes.RESOURCE && <span>{name}</span>}
                                </Table.Cell>
                                <Table.Cell>
                                    <Icon
                                        link
                                        name='copy'
                                        onClick={() => copyToClipboard(tag)}
                                    />
                                    {tag}
                                </Table.Cell>
                                <Table.Cell>
                                    {dataTypeDescriptions[dataType]}
                                </Table.Cell>
                                <Table.Cell>
                                    {required ? 'Ja' : 'Nej'}
                                </Table.Cell>
                            </Table.Row>
                        );
                    })}
                </Table.Body>
            </Table>
            {annualRolloverValues?.length > 0 && (
                <>
                    <h3>Værdier til årsrulning</h3>
                    <List bulleted>
                        {annualRolloverValues.map(({ id, tag }) => (
                            <List.Item key={id}>{tag}</List.Item>
                        ))}
                    </List>
                </>
            )}
        </>
    );
};

const SubResourceEditorModal = ({ subResource, setSubResource, signatures, chosenResource, closeModal }) => {
    const [activeTabIdx, setActiveTabIdx] = useState(0);
    
    if (!subResource) {
        return null;
    }

    const getAllTags = () => {
        return collectAllTagsAssociatedWithResource({
            chosenResource,
            resourceData: subResource,
        });
    };

    const tabs = [
        {
            name: 'Spørgsmål',
            render() {
                return (
                    <QuestionEditor
                        questions={subResource?.questions || []}
                        onChange={questions => setSubResource({ ...subResource, questions })}
                    />
                );
            },
        },
        {
            name: 'Udregninger',
            render() {
                return (
                    <CalculationEditor
                        calculations={subResource?.calculations || []}
                        fieldIds={getAllTags()}
                        signatures={signatures}
                        onChange={calculations => setSubResource({ ...subResource, calculations })}
                    />
                );
            },
        },
        {
            name: 'Feltskabeloner',
            render() {
                return (
                    <FieldTemplateEditor
                        fieldIds={getAllTags()}
                        fieldTemplates={subResource?.fieldTemplates || []}
                        onChange={fieldTemplates => setSubResource({ ...subResource, fieldTemplates })}
                        radioOptionsGetter={() => []}
                    />
                );
            },
        },
    ];

    const activeTab = tabs[activeTabIdx];

    return (
        <Modal size='fullscreen' open closeIcon onClose={closeModal}>
            <Modal.Header>
                Redigér underressource
            </Modal.Header>
            <Modal.Content scrolling>
                <Menu>
                    {tabs.map((tab, idx) => {
                        return (
                            <Menu.Item
                                active={idx === activeTabIdx}
                                content={tab.name}
                                onClick={() => setActiveTabIdx(idx)}
                            />
                        );
                    })}
                </Menu>
                {activeTab.render()}
            </Modal.Content>
        </Modal>
    );
};

const GeneralSettingsEditor = ({ resourceTypes, chosenResource, updateData, useTableWidgetForCreation, subResourcesConfig, signatures }) => {
    const [selectedSubResourceTag, setSelectedSubResourceTag] = useState(null);

    let selectedSubResource;
    let selectedSubResourceAssociatedTemplate;

    if (selectedSubResourceTag) {
        const resourceProperty = chosenResource.properties.find(x => x.tag === selectedSubResourceTag);

        selectedSubResource = subResourcesConfig[selectedSubResourceTag] || {};
        selectedSubResourceAssociatedTemplate = resourceTypes.find(template => template.slug === resourceProperty.subResourceTemplateSlug);
    }

    return (
        <div>
            <Header>Vælg ressource:</Header>
            <Dropdown
                search
                selection
                fluid
                defaultValue={chosenResource?.slug}
                options={resourceTypes.map(({ slug, name }) => ({
                    text: name,
                    value: slug,
                }))}
                onChange={(_, { value }) => {
                    updateData({ resource: value });
                }}
            />
            {chosenResource && <ResourceDescription {...chosenResource} onSubResourceSelected={setSelectedSubResourceTag} />}
            <Checkbox
                toggle
                label='Vis som tabel ved oprettelse?'
                checked={useTableWidgetForCreation}
                onChange={(_, { checked }) => updateData({ useTableWidgetForCreation: checked })}
            />
            <SubResourceEditorModal
                signatures={signatures}
                resourceTypes={resourceTypes}
                subResource={selectedSubResource}
                chosenResource={selectedSubResourceAssociatedTemplate}
                closeModal={() => setSelectedSubResourceTag(null)}
                setSubResource={newSubResourceData => {
                    const newSubResourcesConfig = { ...subResourcesConfig };
                    newSubResourcesConfig[selectedSubResourceTag] = newSubResourceData;
                    updateData({ subResourcesConfig: newSubResourcesConfig });
                }}
            />
        </div>
    );
};

export default GeneralSettingsEditor;